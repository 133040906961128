/** @format */

import { derived } from 'svelte/store';
import { options, transmitter, bankruptIsPerson } from 'base_stores';
import { currentData, allPossessions, allOrganizationPossessions, creditorsRequirements } from './stores';
import moment from 'moment';
import { isPresent, toArray, formatSum, formatDate, isNumber, toFloat } from 'utils/tools';
import { currencyFormat } from 'utils/formats';

// данные для заполнения таблиц
export const tablesData = derived(
  [transmitter, options, bankruptIsPerson, allPossessions, allOrganizationPossessions, creditorsRequirements, currentData],
  ([$transmitter, $options, $bankruptIsPerson, $allPossessions, $allOrganizationPossessions, $creditorsRequirements, $currentData]) => {

  const measureKinds = $options?.measureKind;
	const specificOrgExpenseTypes = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 32].map(i => `org${i}`);
	const specificExpenseTypes = $bankruptIsPerson ? [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21].map(i => `person${i}`) : specificOrgExpenseTypes
  const procedurePhase = $transmitter.procedure.phase;
  const procedureKinds = { debt_restructuring: 'person1', property_realization: 'person2', supervision: 'org1', bankruptcy_proceedings: 'org6' }
	const specificProcedureKind = procedureKinds[procedurePhase] || ''

	return {
    bank_accounts: {
      columns: [
        'index',
        'counterparty_name',
        'bank_legal_address',
        'name_with_legal_address',
        'account_details',
        'receipts_sum_rub',
        'current_balance_rub',
        'balance_rub',
        'measures_taken',
        'result'
      ],
      sums: {},
      resources: toArray($transmitter.bankAccounts).map(a => ({
        ...a,
        bank_legal_address: a.bank_legal_address_full,
        name_with_legal_address: [a.counterparty_name, a.bank_legal_address_full].join('; '),
        account_details: `${a.kind_name} №${a.number}`,
        receipts_sum_rub: formatSum(toArray($transmitter.receipts).filter(r => +r.bank_account_id == +a.id).reduce((sum, r) => sum + parseFloat(r.sum), 0.0)),
      })),
    },
    complaints: {
      columns: ['name', 'date', 'content', 'article', 'decisionmaker', 'consideration_date', 'final_document', 'decision', 'decision_revision'],
      sums: {},
      resources:
        $transmitter.complaints &&
        $transmitter.complaints.map(c => {
          const contentInfo = [c['content'], c['article']].filter(i => i).join(' ');
          const finalDocumentInfo = [c['final_document'], c['consideration_date'] ? `от ${formatDate(c['consideration_date'], 'DD.MM.YYYY')} г.` : null]
            .filter(i => i)
            .join(' ');
          return {
            ...c,
            content: isPresent(contentInfo) ? contentInfo : '-',
            final_document: isPresent(finalDocumentInfo) ? finalDocumentInfo : '-',
          };
        }),
    },
    creditors_requirements: {
      columns: ['index', 'counterparty_name', 'counterparty_name_inn', 'amount', 'paid_sum', 'paid_percent', 'paid_date'],
      sums: {
        requirements_total: {
          total_on_reestr: {
            total_first_stage: { first_stage: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
            total_second_stage: { second_stage: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
            total_third_stage: {
              third_stage_total_secured_by_pledge: { third_stage_secured_by_pledge: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
              third_stage_total_not_secured_by_pledge: { third_stage_not_secured_by_pledge: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
              third_stage_total_percents: { third_stage_percents: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
              third_stage_total_fines: { third_stage_fines: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
            },
          },
          total_off_reestr: { off_reestr: ['amount', 'paid_sum', 'paid_percent', 'paid_date'] },
        },
      },
      resources: $creditorsRequirements,
    },
    debt_restructuring_costs: {
      columns: ['index', 'expense_type_text', 'expense_goal_name', 'sum', 'protocol'],
      sums: { total: { cell: ['sum'] } },
      resources: toArray($transmitter.running_costs).filter(r => r.procedure_kind == specificProcedureKind && specificExpenseTypes.includes(r.expense_type)),
    },
    employees_working: {
      columns: ['index', 'full_name', 'position'],
      sums: {},
      resources: $transmitter?.employees?.filter(e => !e.dismissal_date || e.dismissal_date > $currentData.date),
    },
    employees_dismissed: {
      columns: ['index', 'full_name', 'position', 'dismissal_date'],
      sums: {},
      resources: $transmitter?.employees?.filter(e => e.dismissal_date && e.dismissal_date <= $currentData.date),
    },
    incomes: { columns: ['index', 'year', 'sum'], sums: {} },
    measures: {
      columns: ['name', 'result'],
      sums: {},
      resourceKinds: measureKinds?.map((k, i) => ({ ...k, text: `${i + 1}. ${k.text}` })),
      resources:
        $transmitter.measures &&
        Object.fromEntries(
          Object.entries($transmitter.measures)?.map(([k, v]) => [
            k,
            v.map(r => ({
              ...r,
              result: [
                isPresent(r.result) ? `${r.result},` : null,
                isPresent(r.judicial_act) ? r.judicial_act : null,
                moment(r.judicial_act_date).isValid() ? ` от ${moment(r.judicial_act_date).format('DD.MM.YYYY')} г., ` : null,
                isPresent(r.court_name) ? r.court_name : null,
              ]
                .filter(i => i)
                .join(' '),
            })),
          ]),
        ),
    },
    meetings: {
      columns: ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication'],
      sums: {},
      resources: ($transmitter?.meetings?.creditors || []).sort((a, b) => a.id - b.id),
    },
    monitoring_costs: {
      columns: ['index', 'expense_type_text', 'expense_goal_name', 'sum', 'protocol'],
      sums: { total: { cell: ['sum'] } },
      resources: toArray($transmitter.running_costs).filter(r => r.procedure_kind == specificProcedureKind && specificOrgExpenseTypes.includes(r.expense_type)),
    },
    specialists: {
      columns: [
        'kind_name',
        'accreditation',
        'name',
        'contract',
        'contract_date',
        'contract_completion_date',
        'payment',
        'payment_source_name',
        'payment_frequency_name',
        'court_decision_date',
      ],
      // %%SPECIALISTS_CELL_...%% - в ячейке, %%SPECIALISTS_TOTAL_...%% - в суммирующей ячейке.
      sums: { total: { cell: [] } }, //{ total: { cell: ['payment'] } }
      resources:
        $transmitter.specialists &&
        $transmitter.specialists.map(s => {
          const contractInfo = [
            s['contract'],
            isPresent(s['contract_date']) ? `от ${formatDate(s['contract_date'], 'DD.MM.YYYY')} г.` : null,
            isPresent(s['contract_completion_date']) ? `действует до ${formatDate(s['contract_completion_date'], 'DD.MM.YYYY')} г.` : null,
          ]
            .filter(i => i)
            .join(' ');
          const paymentInfo = [isNumber(toFloat(s['payment'])) ? `${currencyFormat(toFloat(s['payment']))} руб.` : null, s['payment_frequency_name']]
            .filter(i => i)
            .join(' ');

          return { ...s, contract: isPresent(contractInfo) ? contractInfo : '-', payment: isPresent(paymentInfo) ? paymentInfo : '-' };
        }),
    },
    kdls: {
      columns: ['index', 'full_name', 'submission_claims_date', 'amount_of_claims', 'result'],
      sums: { total: { cell: ['amount_of_claims'] } },
      resources: $transmitter.kdls,
    },
    organization_possessions: {
      columns: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
      sums: {},
      resources: $allOrganizationPossessions,
    },
    possessions_included_to_bankruptcy: {
      columns: [
        'name',
        'sale_method',
        'contract_date',
        'contract_number',
        'cost_under_contract',
        'contract_result',
        'contract_result_sum',
      ],
      sums: {
        total: { cell: ['cost_under_contract', 'contract_result_sum'] },
        total_land: {
          land: ['cost_under_contract']
        }
      },
      resources: $allPossessions.possessionsIncludedToBankruptcy.map(p => ({
        ...p,
        sale_method: p.sale_method || '-',
        contract_number: p.contract_number || 'б/н',
        contract_result:
          p.contract_terminated ?
            p.info_on_termination_of_contract :
            (p.receipts || []).map(r => (formatSum(r.sum) + ' (' + formatDate(r.date_at) + ')')).join(';<br/>') || '-',
        contract_result_sum: (p.receipts || []).reduce((sum, r) => sum + parseFloat(r.sum), 0.0),
      })),
    },
    possessions: {
      columns: [
        'name',
        'included_arbitr_manager',
        'included_appraiser',
        'pledgee',
        'excluded',
        'exclusion_grounds',
        'excluded_arbitr_manager',
        'excluded_appraiser',
        'cost_under_contract',
      ],
      sums: {
        generic_total: {
          generic_realty: {
            total_generic_subjects_land: {
              generic_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
            },
            total_generic_subjects_residential: {
              generic_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
            },
            total_generic_subjects_non_residential: {
              generic_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
            },
          },
          total_generic_subjects_transport: {
            generic_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
          },
          total_generic_subjects_share: {
            generic_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
          },
          total_generic_subjects_other: {
            generic_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
          },
          money: {
            total_bank_accounts: {
              bank_accounts: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
            },
            total_receivables: {
              receivables: ['included_arbitr_manager', 'included_appraiser', 'excluded'],
            },
          },
        },
        no_pledge_total: {
          no_pledge_realty: {
            total_no_pledge_subjects_land: {
              no_pledge_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
            total_no_pledge_subjects_residential: {
              no_pledge_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
            total_no_pledge_subjects_non_residential: {
              no_pledge_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
          },
          total_no_pledge_subjects_transport: {
            no_pledge_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
          total_no_pledge_subjects_share: {
            no_pledge_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
          total_no_pledge_subjects_other: {
            no_pledge_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
          money: {
            total_bank_accounts: {
              bank_accounts: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
            total_receivables: {
              receivables: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
          },
        },
        pledge_total: {
          pledge_realty: {
            total_pledge_subjects_land: {
              pledge_subjects_land: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
            total_pledge_subjects_residential: {
              pledge_subjects_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
            total_pledge_subjects_non_residential: {
              pledge_subjects_non_residential: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
            },
          },
          total_pledge_subjects_transport: {
            pledge_subjects_transport: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
          total_pledge_subjects_share: {
            pledge_subjects_share: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
          total_pledge_subjects_other: {
            pledge_subjects_other: ['included_arbitr_manager', 'included_appraiser', 'excluded_arbitr_manager', 'excluded_appraiser'],
          },
        },
      },
      resources: $allPossessions,
    },
    receipts: {
      columns: ['counterparty_name', 'receipt_date', 'receipt_sum'],
      sums: { total: { cell: ['receipt_sum'] } },
      resources: toArray($transmitter.receipts)
        .filter(a => a.account_is_main)
        .map(r => ({
          counterparty_name: `${r.counterparty_name}${isPresent(r.note) ? ` (${r.note})` : ''}`,
          receipt_date: r.date_at,
          receipt_sum: formatSum(r.sum),
        })),
    },
    receipts_payouts: {
      columns: ['bank_name', 'bank_location', 'account_kind', 'receipt_sum', 'receipt_date', 'payout_sum', 'payout_date', 'payout_doc'],
      sums: {},
      resources: [...toArray($transmitter.receipts).map(e => ({ type: 'receipt', ...e })), ...toArray($transmitter.payouts).map(e => ({ type: 'payout', ...e }))]
        .sort((a, b) => (a.date_at > b.date_at ? 1 : b.date_at > a.date_at ? -1 : 0))
        .map(r => ({
          bank_name: r.bank_name,
          bank_location: r.bank_location,
          account_kind: [r.account_number, r.account_kind].filter(e => isPresent(e)).join(' '),
          receipt_sum: r.type == 'receipt' ? formatSum(r.sum) : '',
          receipt_date: r.type == 'receipt' ? r.date_at : '',
          payout_sum: r.type == 'payout' ? formatSum(r.sum) : '',
          payout_date: r.type == 'payout' ? r.date_at : '',
          payout_doc: `платежный документ от ${formatDate(r.num_at)} №${r.num}, ${r.note}`,
        })),
    },
    receivables: {
      columns: ['index', 'counterparty_name', 'debt_amount', 'share_in_total_debt', 'paid_sum', 'note'],
      sums: { total: { cell: ['debt_amount', 'share_in_total_debt', 'paid_sum'] } },
      resources: toArray($transmitter.receivables),
    },
    running_costs: {
      columns: [
        'index',
        'counterparty_name',
        'procedure_kind_text',
        'expense_type_text',
        'expense_goal_name',
        'date',
        'sum',
        'maturity_date',
        'paid_sum',
        'outstanding_sum',
      ],
      sums: {
        stages_total: {
          total_first_stage: { first_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
          total_second_stage: { second_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
          total_third_stage: { third_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
          total_fourth_stage: { fourth_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
          total_fifth_stage: { fifth_stage: ['sum', 'paid_sum', 'outstanding_sum'] },
        },
      },
      resources:
        $transmitter.running_costs &&
        $transmitter.running_costs.map(r => ({
          ...r,
          expense_type_text: [r.expense_type_text, r.expense_reason].filter(i => isPresent(i)).join('.<br/>'),
        })),
    },
    worker_meetings: {
      columns: ['date_at', 'agenda', 'solutions', 'publication_about', 'solution_publication'],
      sums: {},
      resources: ($transmitter?.meetings?.employees || []).sort((a, b) => a.id - b.id),
    },
  };
});

/** @format */

import { get } from 'svelte/store';
import { transmitter as _transmitter, options } from 'base_stores';
import {
	actualMeasures, pdfDctData,
	currentData as _currentData,
	requiredFieldsFilled as _requiredFieldsFilled,
	creditorsRequirements as _creditorsRequirements,
	allPossessions as _allPossessions,
} from './stores/stores';
import { tablesData as _tablesData } from './stores/tables_data';
import { triggers, derivedData as _derivedData } from './stores/analytics';
import {
	reworkingStyles,
	reworkingMeetingStyles,
	clearFields,
	transformOtherFields,
	transformBankruptCategoryFields,
	transformAmountFields,
	transformPercentFields,
	transformRegistrarFields,
	transformSurnameFields,
	transformFormProvidingInformation,
	transformObtainingMethodFields,
	transformChildrenBlock,
	transformSpousesBlock,
	transformIncomeSourcesTable,
	transformMeasuresTextBlock,
	transformSentNotificationsRequestsBlock,
	transformAnswersBlock,
	transformAnswersFnsPfrFssBlock,
	transformAnswersAboutPropertyList,
	transformPersonAssetsTable,
	transformPersonLiabilitiesTable,
	transformPersonResponsibilitiesTable,
	transformPersonPossessionsTable,
	transformArbitrManagerAdjectiveFields,
	transformOptionalParagraph,
	insertBankAccounts,
	transformTriggers,
	transformRunningCostsBlock,
	transformReceiptsBlock,
	transformPayoutsBlock,
	transformReceiptsPayoutBlock,
	transformUniqueFields,
	transformTable,
	transformArbitraryText,
	transformAdditionalArbitraryText,
	transformSignature,
	transformMeetingClauses,
	transformMeetingQuestions,
	transformMeetingSolutions,
	transformMeetingFields,
	transformAppendices,
	transformBankruptInfo,
} from './transformations';
import Case from 'case';
import moment from 'moment';
import { isPresent, isBlank, toArray } from 'utils/tools';

// из-за несоответствия написания названий полей в шаблонах заменяем их по подготовленной карте замен.
// TODO: прийти к тому, чтоб видимое пользователю описание поля в шаблоне не привязывалаось к истинному написанию названия поля,
// тогда исправить написание полей в шаблонах 
const actualizeFields = (template, keys) => {
	const replacements = {
		region_id: 'region',
		realty_location_id: 'realty_location',
		sro_id: 'sro_full_name',
		correspondent_id: 'correspondent_short_name',
		court_id: 'court_full_name',
		appendices: 'appendices_list',
	};

	// заменем тэги соответствующими ключами из содержимого документа в соответствии с картой замен
	for (let key of Object.keys(replacements)) {
		template = template.replace(RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g'), `%%${Case.upper(replacements[key], '_')}%%`);
		keys[keys.indexOf(key)] = replacements[key];
	}

	return [template, keys]
}

// TODO: сюда из формы передаётся одно поле вида %%...%%. слишком жирно.
// надо переделать обработку шаблона, передавать весь шаблон и не в этот метод, а в getTemplate
export const templateFieldSubstitution = (template) => {
	const transmitter = get(_transmitter);
	const dct = transmitter.dct;
	const substitutions = dct.substitutions;
	const isDraft = dct.status === 'draft';
	const derivedData = get(_derivedData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const currentData = get(_currentData);

	// ============================================================================================================
	let keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	[template, keys] = actualizeFields(template, keys);

	let templates = { preview: template, pdfData: template };
	// ============================================================================================================

	if (/%%SPOUSES%%/.test(templates.preview)) {
		const spouses = currentData.spousesIds ? (transmitter.spouses || []).filter(spouse => currentData.spousesIds.includes(spouse.id)) : transmitter.spouses;
		templates = transformSpousesBlock(templates, spouses, isDraft); // это есть в другой ветке
	}

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}
		templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (!fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (!fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor)/.test(key) ||
			[
				'book_property_value',
				'market_property_value',
				'monthly_expenses',
				'debt_to_creditors_from_statement_of_receivable',
				'living_wage_for_bankrupt',
				'free_funds_of_bankrupt',
			].includes(key)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (/%%SIGNATURE%%/.test(templates.preview) && substitutions.signature) {
			templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature); // это есть в другой ветке
		}
		if (['appendices', 'appendices_list'].includes(key) && /%%(APPENDICES_LIST|APPENDICES)%%/.test(templates.preview)) {
			templates = transformAppendices(templates, substitutions, transmitter.procedure, isDraft); // это есть в другой ветке
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	templates = clearFields(templates, isDraft);

	return templates;
};

export const getTemplate = (transmitter = get(_transmitter)) => {
	const dct = transmitter.dct;

	if (isBlank(dct)) { return { preview: '', pdfData: '' } }

	const currentData = get(_currentData);
	const requiredFieldsFilled = get(_requiredFieldsFilled);
	const creditorsRequirements = get(_creditorsRequirements);
	const allPossessions = get(_allPossessions);
	const derivedData = get(_derivedData);
	const tablesData = get(_tablesData);
	const bankruptCategories = get(options)?.bankruptCategory;
	const undeclaredDebts = transmitter?.undeclaredDebts;
	const substitutions = dct.substitutions;
	const isDraft = dct.status == 'draft';

	// let template = dct.category == 'financial_analysis' ? dct.template : encodeTemplate(dct.template, applyStyles);

	// ============================================================================================================
	let template = dct.template; // !!
	let keys = [
		...new Set([...dct.form_fields.map(key => Case.snake(key)), ...Object.keys(substitutions), ...Object.keys(derivedData).map(key => Case.snake(key))]),
	];

	[template, keys] = actualizeFields(template, keys);

	// !! - так комментируем новый код на пути объединения схем обработки шаблонов
	template = template
		.replace(/>\s+</g, '><')
		.replace(
			/<p((?!<).)*?>(\s*<span((?!<))*.)*%%APPENDICES%%(((?!>).)*?)(<\/span>)*\s*<\/p>/g,
			`<div class="mce-content-raf-tag-block" data-tag="%%APPENDICES_LIST%%">%%APPENDICES_LIST%%</div>`
		)

	let templates = { preview: template, pdfData: template };
	// ============================================================================================================

	// расставляем фрагменты шаблона в соответствии с триггерами
	templates = transformTriggers(templates, get(triggers));
	// удаляем лишнюю стилизацию от MS необходимую для оформления документа как текста электронного письма
	if (transmitter.dct.category == 'meetings') {
		templates = reworkingMeetingStyles(templates);
	} else {
		templates = reworkingStyles(templates);
	}
	// определяемся с названием АУ для текущего документа: 'временным' или 'арбитражным'
	templates = transformArbitrManagerAdjectiveFields(templates, requiredFieldsFilled);
	// подставляем текст категории банкрота
	templates = transformBankruptCategoryFields(templates, substitutions, bankruptCategories, isDraft, requiredFieldsFilled);
	// TODO: переделать в триггер
	if (substitutions.form_providing_information) {
		templates = transformFormProvidingInformation(templates, substitutions, isDraft);
	}
	// заполняем таблицы
	// TODO: тупо прогонять заполнение таблицы для каждого ключа - жирно, надо вытаскивать из шаблона ключи талиц и прогонять заполнение только их
	for (let resourceName in tablesData) {
		templates = transformTable(templates, resourceName, tablesData[resourceName], isDraft);
	}

	// заменяем конструкцию if-endif у темплейтов собраний
	templates = transformMeetingClauses(templates, substitutions);
	// заменяем все поля собрания
	templates = transformMeetingFields(templates, substitutions, isDraft);

	const possessionsSubjectsKeys = Object.keys(allPossessions).filter(key => /^(possessionsSubject|possessionsBankAccounts|possessionsReceivables).*/.test(key));

	for (let key of possessionsSubjectsKeys) {
		if (RegExp(`%%${Case.upper(key, '_')}.*?%%`, 'g').test(templates.preview)) {
			templates = transformPersonPossessionsTable(templates, Case.upper(key, '_'), allPossessions[key]);
		}
	}

	if (/%%NUMBER_MEETINGS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'number_meetings',
			((transmitter.meetings && transmitter.meetings.creditors) || []).length,
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%(APPENDICES_LIST|APPENDICES)%%/.test(templates.preview)) {
		templates = transformAppendices(templates, substitutions, transmitter.procedure, isDraft);
	}
	if (/%%NUMBER_WORKER_MEETINGS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'number_worker_meetings',
			((transmitter.meetings && transmitter.meetings.employees) || []).length,
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%SPOUSES%%/.test(templates.preview)) {
		const spouses = currentData.spousesIds ? (transmitter.spouses || []).filter(spouse => currentData.spousesIds.includes(spouse.id)) : transmitter.spouses;
		templates = transformSpousesBlock(templates, spouses, isDraft);
	}
	if (/%%MINOR_CHILDREN_OF_BANKRUPT%%/.test(templates.preview)) {
		templates = transformChildrenBlock(templates, transmitter.children, substitutions.minor_children_of_bankrupt, isDraft);
	}
	if (/%%ANSWERS%%/.test(templates.preview)) {
		templates = transformAnswersBlock(templates, null, transmitter.answers, isDraft);
	}
	if (/%%SENT_NOTIFICATION_REQUESTS%%/.test(templates.preview)) {
		templates = transformSentNotificationsRequestsBlock(templates, null, transmitter.sentNotificationsRequests, isDraft);
	}
	if (/%%MEASURES_TEXT%%/.test(templates.preview)) {
		templates = transformMeasuresTextBlock(templates, get(actualMeasures));
	}
	if (/%%ANSWERS_FNS_PFR_FSS%%/.test(templates.preview)) {
		const answers = transmitter.answers && transmitter.answers.filter(answer => ['fns', 'pfr', 'fss'].includes(answer.correspondent_kind));
		templates = transformAnswersFnsPfrFssBlock(templates, answers, isDraft);
	}
	if (/%%ANSWERS_ABOUT_PROPERTY%%/.test(templates.preview)) {
		const answers =
			transmitter.answers &&
			transmitter.answers.filter(answer =>
				['gibdd', 'mchs', 'gtn', 'mvd', 'fsis', 'favt', 'bti', 'fns', 'rosgvardiya', 'rosreestr'].includes(answer.correspondent_kind),
			);
		templates = transformAnswersAboutPropertyList(templates, answers);
	}
	if (/%%ASSETS.*?%%/.test(templates.preview)) {
		let assets = { ...allPossessions, other: allPossessions.other.filter(procession => !procession.onlyResidence) };
		templates = transformPersonAssetsTable(templates, assets);
	}
	if (/%%LIABILITIES.*?%%/.test(templates.preview)) {
		templates = transformPersonLiabilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
	}
	if (/%%RESPONSIBILITIES.*?%%/.test(templates.preview)) {
		templates = transformPersonResponsibilitiesTable(templates, creditorsRequirements.allBesidesExcluded, undeclaredDebts);
	}
	if (/%%REALTY.*?%%/.test(templates.preview)) {
		const possessions = [
			...allPossessions.possessionsNoPledgeSubjectsLand,
			...allPossessions.possessionsNoPledgeSubjectsResidential,
			...allPossessions.possessionsNoPledgeSubjectsNonResidential,
			...allPossessions.possessionsPledgeSubjectsLand,
			...allPossessions.possessionsPledgeSubjectsResidential,
			...allPossessions.possessionsPledgeSubjectsNonResidential,
		];
		templates = transformPersonPossessionsTable(templates, 'REALTY', possessions);
	}
	if (/%%MOVABLES.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsTransport, ...allPossessions.possessionsPledgeSubjectsTransport];
		templates = transformPersonPossessionsTable(templates, 'MOVABLES', possessions);
	}
	if (/%%SHARES.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsShare, ...allPossessions.possessionsPledgeSubjectsShare];
		templates = transformPersonPossessionsTable(templates, 'SHARES', possessions);
	}
	if (/%%OTHER_POSSESSIONS.*?%%/.test(templates.preview)) {
		const possessions = [...allPossessions.possessionsNoPledgeSubjectsOther, ...allPossessions.possessionsPledgeSubjectsOther];
		templates = transformPersonPossessionsTable(templates, 'OTHER_POSSESSIONS', possessions);
	}
	if (/%%BANK_ACCOUNTS((?!_LIST).)*?%%/.test(templates.preview)) {
		templates = transformPersonPossessionsTable(templates, 'BANK_ACCOUNTS', transmitter.bankAccounts);
	}
	if (/%%INCOME_SOURCES.*?%%/.test(templates.preview)) {
		templates = transformIncomeSourcesTable(templates, transmitter.incomeSources, isDraft);
	}
	if (/%%SIGNATURE%%/.test(templates.preview) && substitutions.signature) {
		templates = transformSignature(templates, transmitter.procedure.arbitr_manager.signature);
	}
	if (/%%GRAND_TOTAL_RUNNING_COST%%/.test(templates.preview) || /%%.*?_RUNNING_COST_STAGE%%/.test(templates.preview)) {
		templates = transformRunningCostsBlock(templates, transmitter);
	}
	if (/%%RECEIPTS%%/.test(templates.preview)) {
		templates = transformReceiptsBlock(templates, transmitter);
	}
	if (/%%PAYOUTS%%/.test(templates.preview)) {
		templates = transformPayoutsBlock(templates);
	}
	if (/%%RECEIPTS_AND_PAYOUTS%%/.test(templates.preview)) {
		templates = transformReceiptsPayoutBlock(templates, transmitter);
	}
	for (let key of ['analisys_conclusion', 'arbitrary_text']) {
		let value = substitutions[key];
		if (key == 'arbitrary_text') {
			value = isPresent(substitutions[key]) ? substitutions[key] : substitutions['dct_arbitrary_text'];
		}
		templates = transformArbitraryText(templates, key, value, isDraft, requiredFieldsFilled[key]);
	}
	if (/%%ADDITIONAL_ARBITRARY_TEXT%%/.test(templates.preview)) {
		templates = transformAdditionalArbitraryText(templates, substitutions['arbitrary_text'], isDraft);
	}
	if (/%%BANKRUPT_INFO%%/.test(templates.preview)) {
		templates = transformBankruptInfo(templates, transmitter.procedure, isDraft);
	}
	if (/%%CREDITORS_FIRST_MEETING_DATE%%/.test(templates.preview)) {
		templates = transformUniqueFields(
			templates,
			'creditors_first_meeting_date',
			isDraft ? currentData.creditorsFirstMeetingMinutesDate : currentData.creditorsFirstMeetingMinutesDate || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_AGENDA%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_agenda',
			isDraft ? currentData.creditorsFirstMeetingAgenda : currentData.creditorsFirstMeetingAgenda || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_SOLUTIONS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_solutions',
			isDraft ? currentData.creditorsFirstMeetingSolutions : currentData.creditorsFirstMeetingSolutions || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}
	if (/%%CREDITORS_FIRST_MEETING_ARBITR_PROPOSALS%%/.test(templates.preview)) {
		templates = transformOtherFields(
			templates,
			'creditors_first_meeting_arbitr_proposals',
			isDraft ? currentData.creditorsFirstMeetingArbitrProposals : currentData.creditorsFirstMeetingArbitrProposals || '<div style="width: 100%; text-align: center;">-</div>',
			isDraft,
			requiredFieldsFilled,
		);
	}

	const substitutionsKeys = Object.keys(substitutions);
	const derivedDataKeys = Object.keys(derivedData).map(key => Case.snake(key));

	for (let key of keys) {
		if (key.endsWith('surname')) {
			templates = transformSurnameFields(templates, key, substitutions, isDraft, requiredFieldsFilled);
		}
		if (['obtaining_method', 'recipient_of_accounting_statement'].includes(key)) {
			templates = transformObtainingMethodFields(templates, key, substitutions[key]);
		}
		if (/(BANK_ACCOUNTS_LIST%%|BANK_ACCOUNTS_INLINE_LIST%%|%%MAIN_BANK_ACCOUNT%%)/.test(templates.preview)) {
			templates = insertBankAccounts(templates, key, substitutions, isDraft, transmitter);
		}
		if (/judicial_act_date/.test(key)) {
			key = 'judicial_act_date';
		}
		templates = transformRegistrarFields(templates, key, substitutions, isDraft, requiredFieldsFilled);

		// эти 3 строки для сохранения fieldText равным нулю, простое || трактует 0 как false
		let fieldText = currentData[Case.camel(key)];
		if (substitutionsKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = substitutions[key];
		}
		if (derivedDataKeys.includes(key) && !fieldText && fieldText !== 0) {
			fieldText = derivedData[Case.camel(key)];
		}

		if (/percent/.test(key) || /external_factor_/.test(key) || /internal_factor_/.test(key)) {
			templates = transformPercentFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (
			/(amount|balance|monthly_expenses_of_debtor_|debt_to_creditors_from_statement_of_debtor|living_wage_for)/.test(key) ||
			['book_property_value', 'market_property_value', 'monthly_expenses', 'debt_to_creditors_from_statement_of_receivable', 'free_funds_of_bankrupt'].includes(
				key,
			)
		) {
			templates = transformAmountFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		}
		if (key == 'meeting_questions') {
			templates = transformMeetingQuestions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (key == 'meeting_solutions') {
			templates = transformMeetingSolutions(templates, isDraft ? fieldText : substitutions[key]);
			continue;
		}
		if (
			[
				'prolongations',
				'prolongations_resolution',
				'renewal_period',
				'completion_date_after_renewal',
				'completion_date_after_renewal_or_completion_date',
			].includes(key)
		) {
			fieldText = transmitter.procedure.prolongations;
		}
		if (['arbitr_manager_insurance_orgs', 'arbitr_manager_insurance_policies'].includes(key)) {
			const procedure = transmitter.procedure;
			const introductionDates = [procedure.resolution_introduction_date, procedure.introduction_date].filter(i => i);
			const dateStart = isPresent(introductionDates) ? introductionDates.reduce((min, c) => (c < min ? c : min)) : '2000-01-01';
			const completions = [procedure.completion_date, ...toArray(procedure.prolongations).map(p => p.completion_date)].filter(i => i);
			const dateFinish = isPresent(completions) ? completions.reduce((max, c) => (c > max ? c : max)) : '2100-12-31';

			fieldText = toArray(transmitter.insurancePolicies).filter(
				p =>
					p.proprietor_type == 'ArbitrManager' &&
					((isPresent(p.date_start) ? moment(p.date_start).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_finish) ? moment(p.date_finish).isBetween(dateStart, dateFinish, undefined, '[]') : true) ||
						(isPresent(p.date_start) && isPresent(p.date_finish)
							? moment(dateStart).isBetween(p.date_start, p.date_finish, undefined, '[]') ||
								moment(dateFinish).isBetween(p.date_start, p.date_finish, undefined, '[]')
							: true)),
			);
		}

		templates = transformUniqueFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOtherFields(templates, key, fieldText, isDraft, requiredFieldsFilled);
		templates = transformOptionalParagraph(templates, substitutions.insert_first_paragraph);
	}

	templates = clearFields(templates, isDraft);

	pdfDctData.update(() => templates.pdfData);

	return templates;
};

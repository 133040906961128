<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { procedure, transmitter, newObject } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { dcts, currentData, tags } from '../stores/stores';
	import { fetchGet, fetchPost } from 'utils/fetch';
	import Header from '~/svelte/components/header.svelte';
	import Modal, { hideModal, showModal } from '~/svelte/components/modal.svelte';
	import DctsMetaData from '../dcts_meta_data.svelte';
	import Case from 'case';
	import { isPresent, isBlank, groupBy } from 'utils/tools';
	import MailRegistryForm from '../../mail_registries/form.svelte';
	import TagFilter from '~/svelte/components/ui/tag_filter.svelte';
	import DctMetaRecord from 'models/DctMetaRecord';
	import { getCookie } from 'utils/cookies';
	import { bankruptIsPerson } from '~/js/global_stores/base';

	let dctsMetaData = { priority: [], bank_and_criptoex: [], other: [], for_bank: [] };
	let selectedDctsMetaData = [];
	let sectionType;
	let creditors = [];
	let selectedCreditors = [];
	let relCreditors = [];
	let selectedTags = [];
	let filteredDcts = {};
	let currDctsMetaData = [];
	let columns = 1;
	let mounted;
	let refreshDctMetaData;

	$: procedureId = $procedure?.id;
	$: allSelected = sectionType && selectedDctsMetaData.length === dctsMetaData[sectionType].length && selectedCreditors.length === relCreditors.length;

	$: if ($transmitter?.dcts_meta_data) {
		dctsMetaData = $transmitter.dcts_meta_data;
	} else {
		dctsMetaData = { priority: [], bank_and_criptoex: [], other: [], for_bank: [] };
	}

	$: if (isBlank($dcts)) {
		$transmitter.dcts = { priority: [], bank_and_criptoex: [], other: [], for_bank: [] };
	}

	$: sections = $bankruptIsPerson
		? [
				{ data: $dcts?.priority, title: 'Запросы, уведомления', type: 'priority' },
				{ data: $dcts?.bank_and_criptoex, title: 'Запросы, уведомления в банки, брокерам и криптобиржи', type: 'bank_and_criptoex' },
				{ data: $dcts?.other, title: 'Письма, ходатайства и прочие', type: 'other' },
			]
		: [
				{ data: $dcts?.priority, title: 'Запросы, уведомления', type: 'priority' },
				{ data: $dcts?.other, title: 'Письма, ходатайства и прочие', type: 'other' },
			];

	$: if ($procedure && $procedure.phase === 'bankruptcy_proceedings') {
		sections.splice(1, 0, { data: $dcts?.for_bank, title: 'Запросы, уведомления в банки', type: 'for_bank' });
	}

	$: groups = Object.entries(
		groupBy(
			(isPresent($dcts) ? Object.values($dcts) : []).flat().filter(e => isPresent(e.group_docs)),
			e => e.group_docs,
		),
	)
		.reverse()
		.reduce((h, e, i) => {
			h[e[0]] = { index: i + 1, ids: e[1].map(r => r.id) };
			return h;
		}, {});

	$: if (sections && isPresent(selectedTags)) {
		for (let s of sections) {
			filteredDcts[s.type] = s.data.filter(
				dct => (isPresent(dct.tags) && selectedTags.some(tag => dct.tags.includes(tag))) || (!isPresent(dct.tags) && selectedTags.includes('withoutTags')),
			);
		}
	} else {
		for (let s of sections) {
			filteredDcts[s.type] = s.data;
		}
	}

	const toggleAllCorrespondentKinds = () => {
		selectedDctsMetaData = !allSelected ? dctsMetaData[sectionType] : [];
		selectedCreditors = !allSelected ? relCreditors : [];
	};

	const openDct = id => (location.href = `/documents/outgoing/edit?ids=${id}`);
	const openAnswer = id => (location.href = `/answers/${id}`);

	const createDcts = () => {
		$newObject = true; // от него зависят значения из $currentData переносимые в substitutions
		const dctsParams = selectedDctsMetaData
			.map(metaData => {
				let substitutions = {};
				for (let key of metaData.form_fields) {
					substitutions[Case.snake(key)] = $currentData[key];
				}
				for (let person of ['bankrupt', 'arbitrManager']) {
					if (metaData.form_fields.includes(`${person}FullName`)) {
						for (let key of [`${person}Surname`, `${person}Name`, `${person}Patronymic`]) {
							substitutions[Case.snake(key)] = $currentData[key];
						}
					}
				}

				const creditors = selectedCreditors.filter(e => e.dctMetaData.id == metaData.id);

				if (isPresent(creditors)) {
					return creditors.map(creditor => {
						substitutions[Case.snake('correspondentId')] = creditor.contractor_id + '/' + creditor.contractor_type;
						return { meta_record_id: metaData.id, substitutions: { ...substitutions } };
					});
				} else {
					return { meta_record_id: metaData.id, substitutions };
				}
			})
			.flat();

		const params = { category: 'outgoing', dcts_params: dctsParams, procedure_id: procedureId };

		fetchPost('/api/private/documents', params)
			.then(response => {
				location.href = `/documents/outgoing/edit?ids=${response.dcts.map(dct => dct.id)}&new=true`;
			})
			.catch(errors => console.log('errors: ' + JSON.stringify(errors)));
	};

	const handleCancel = () => (selectedDctsMetaData = []);

	const dctIsDraft = dct => {
		return dct.status === 'draft';
	};

	const showCreateDctsModal = section => {
		sectionType = section.type;
		selectedDctsMetaData = [];
		selectedCreditors = [];
		currDctsMetaData =
			sectionType == 'priority'
				? dctsMetaData.priority.filter(item => !['pfr', 'fss'].includes(item.correspondent_kind))
				: sectionType == 'for_bank'
					? dctsMetaData.for_bank
					: sectionType == 'bank_and_criptoex'
						? dctsMetaData.bank_and_criptoex
						: dctsMetaData.other;
		relCreditors = dctsMetaData[sectionType].map(getCreditors).flat();
		columns = Math.ceil((currDctsMetaData.length + relCreditors.length) / 20);
		showModal('create-dct-modal');
	};

	const getCreditors = dctMetaData => {
		if (dctMetaData.correspondent_kind === 'creditor' && dctMetaData.kind === 'notification') {
			return creditors.filter(e => e.roles.includes('creditor')).map(e => ({ ...e, dctMetaData }));
		} else if (dctMetaData.correspondent_kind === 'bank' && (dctMetaData.kind === 'closure_request' || dctMetaData.kind === 'notification_request')) {
			return creditors.filter(e => e.roles.includes('bank')).map(e => ({ ...e, dctMetaData }));
		} else if (dctMetaData.correspondent_kind === 'cryptoex') {
			return creditors.filter(e => e.roles.includes('cryptoex')).map(e => ({ ...e, dctMetaData }));
		} else {
			return [];
		}
	};

	const addTemplate = async () => {
		hideModal('create-dct-modal');
		const response = await DctMetaRecord.create(DctMetaRecord.new({ category: 'outgoing' }));
		location.href = `/templates/${response.meta_record.id}/edit`;
	};

	const handleDeleteDctMetaRecord = id => {
		DctMetaRecord.delete(id);
	};

	$: if (mounted) {
		refreshDctMetaData = getCookie('_rafRefreshDctMetaRecords', { delete: true });

		if (refreshDctMetaData) {
			location.reload();
		}
	}

	onMount(() => {
		onpageshow = event => {
			if (event.persisted) {
				location.reload();
			}
		};

		fetchGet('/api/private/counterparties/get_options_for_roles', { roles: ['creditor', 'bank', 'cryptoex'] }).then(result => (creditors = result.options));

		mounted = true;
	});
</script>

<Header><span slot="name">Исходящие документы</span></Header>
<div class="mb-5">
	{#if isPresent($tags)}
		<div class="col-sm-6 col-lg-8 col-xl-6 p-1 overlay m-b-sm tag-block">
			<TagFilter tags={$tags} bind:selectedTags />
		</div>
	{/if}
	{#each sections as section}
		<div class="col-sm-6 col-lg-8 col-xl-6 overlay m-b-sm">
			<h3 class="dcts-title">{section.title + ` (${filteredDcts[section.type]?.length || 0})`}</h3>
			<button
				class="btn btn-sm btn-warning m-r-sm m-b-sm"
				disabled={!$procedureAvailable || !procedureId}
				on:click={$procedureAvailable && procedureId && (() => showCreateDctsModal(section))}
			>
				+ Исходящий
			</button>
			{#if isPresent(dctsMetaData[section.type])}
				{#if isPresent(section.data)}
					<ol>
						{#each filteredDcts[section.type] as dct}
							<li>
								<div class="documents-wrap2" role="button" tabindex="0" on:click={$procedureAvailable && (() => openDct(dct.id))} on:keypress|preventDefault>
									<div class="col-8 pl-0">
										<span class:draft={dctIsDraft(dct)} class:done={!dctIsDraft(dct)}>
											{dct.out_full_name}
											<span class="dcts-handle">({dctIsDraft(dct) ? 'черновик' : 'утвержден'})</span>
										</span>
									</div>
									<div class="col-2 px-0">
										{#if isPresent(dct.group_docs)}
											<span>
												<a target="_self" href="/documents/outgoing/edit?ids={groups[dct.group_docs].ids}">
													группа {groups[dct.group_docs].index}
												</a>
											</span>
										{/if}
									</div>
									<div class="col-2 px-0">
										{#if dct.mail_registry}
											<span class="ml-2">
												<a target="_self" href={`/mail_registry/${dct.mail_registry.id}?category=outgoing`}>
													{dct.mail_registry.sent ? 'Отправлен' : 'Не отправлен'}
												</a>
											</span>
										{/if}
									</div>
								</div>
							</li>
							{#if isPresent(dct.answers)}
								{#each dct.answers as answer}
									<div
										class="answer_link col-12 cursor_pointer pl-1"
										role="button"
										tabindex="0"
										on:click={() => openAnswer(answer.id)}
										on:keypress|preventDefault
									>
										<span>{`Ответ получен${isPresent(answer.reply_date) ? ` от ${answer.reply_date}` : ''}`}</span>
									</div>
								{/each}
							{/if}
						{/each}
					</ol>
				{:else}
					<div>Нет документов</div>
				{/if}
			{/if}
		</div>
	{/each}
	<div class="col-sm-6 col-lg-8 col-xl-6 overlay m-b-sm footer-margin">
		<MailRegistryForm {sections} groupsWithIndex={groups} dcts={$dcts} context={{ dct_category: 'outgoing' }} />
	</div>
</div>

<Modal
	modalId="create-dct-modal"
	size={columns < 3 ? 'medium-large' : 'large'}
	submitButtonText="Создать"
	submitButtonAction={procedureId && $procedureAvailable && createDcts}
	submitButtonDisabled={isBlank(selectedDctsMetaData)}
	cancelButtonAction={handleCancel}
>
	<h2 slot="header" class="create-dct-modal__header">Создать документ</h2>
	<div slot="body">
		<ul class="corespondent-kinds-list p-l-xs mb-0" style={`column-count: ${columns}`}>
			{#if ['priority', 'bank_and_criptoex', 'other'].includes(sectionType)}
				<li>
					<label>
						<input class="cursor_pointer" type="checkbox" checked={allSelected} on:click={toggleAllCorrespondentKinds} />
						<div class="meta-data-text">Выбрать все</div>
					</label>
				</li>
			{/if}
			<DctsMetaData
				dctsMetaData={currDctsMetaData}
				creditors={relCreditors}
				bind:selectedDctsMetaData
				bind:selectedCreditors
				on:metaRecordEdit={({ detail: dctMetaDataId }) => (location.href = `/templates/${dctMetaDataId}/edit`)}
				on:metaRecordDelete={({ detail: dctMetaDataId }) => {
					handleDeleteDctMetaRecord(dctMetaDataId);
				}}
			/>
			{#if 'priority'.includes(sectionType)}
				<li>
					<button
						type="button"
						class="btn btn-xs btn-light m-t-xs"
						class:hidden={sectionType != 'priority'}
						style="border: 1px solid #dee2e6;"
						on:click={addTemplate}
					>
						+ Шаблон
					</button>
				</li>
			{/if}
		</ul>
	</div>
</Modal>

<style lang="scss">
	.answer_link {
		color: #676a6c;
	}

	.answer_link:hover {
		text-decoration: underline;
	}

	.tag-block {
		margin-top: 10px;
	}

	.mb-5 {
		display: flow-root;
	}

	.documents-wrap2 {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}

	.corespondent-kinds-list {
		padding-left: 0;

		li {
			list-style-type: none;

			label {
				display: flex;
			}

			.meta-data-text {
				margin-top: -3px;
				font-weight: bold;
			}
		}

		input {
			margin-right: 0.6rem;
		}
	}
</style>

<!-- @format -->
<script>
	import { onMount } from 'svelte';
	import { fetchGet } from 'utils/fetch_helpers';
	import { procedure, transmitter } from 'base_stores';
	import { procedureAvailable } from '~/svelte/components/payment/stores';
	import { isPresent, isBlank, isDate, toArray, formatSum } from 'utils/tools';
	import { formatDate, toDate } from 'utils/date';
	import { currentData, dct } from '~/svelte/dcts/stores/stores';
	import { getTemplate } from '~/svelte/dcts/template.js';
	import Header from '~/svelte/components/header.svelte';
	import DownloadIcon from '~/svelte/components/dashboard/download_icon.svelte';
	import PrintIcon from '~/svelte/components/dashboard/print_icon.svelte';
	import Datepicker from '~/svelte/components/ui/datepicker.svelte';
	import RunningCostModal from '~/svelte/running_costs/_modal.svelte';
	import moment from 'moment';

	let relevanceDate;
	let summaries = {};
	let repayment_orders = {};
	let stages = [];
	let runningCostModal;
	let mounted = false;

	const runningCostsReestrFields = {
		attribute: 'date',
		disabled: false,
		model: 'running_costs',
		label: 'Дата реестра текущих платежей',
		maskOptions: { mask: Date },
		placeholder: 'дд.мм.гггг',
		labelCss: 'col-sm-7 col-lg-8 col-xl-7 col-form-label p-r-none text-nowrap',
		divCss: 'col-sm-5 col-lg-4 col-xl-5',
	};

	const handleNewRunningCostItem = () => runningCostModal.newItem();
	const handleEditRunningCostItem = id => runningCostModal.editItem(id);

	const buildSummary = (running_costs, payouts) => {
		const payout_lines = payouts.map(e => e.payout_lines).flat();
		// group by repayment_order
		summaries = running_costs.reduce((h, r) => {
			(h[r.repayment_order] = h[r.repayment_order] || []).push({
				...r,
				sum: parseFloat(r.sum),
				paid_sum: payout_lines.filter(pl => pl.payoutable_type == 'RunningCost' && pl.payoutable_id == r.id).reduce((s, pl) => s + parseFloat(pl.sum), 0.0),
			});
			return h;
		}, {});

		// add summs
		for (const [key, value] of Object.entries(summaries)) {
			summaries[key] = {
				sum: value.reduce((s, e) => s + e.sum, 0.0),
				paid_sum: value.reduce((s, e) => s + e.paid_sum, 0.0),
				debt_sum: value.reduce((s, e) => s + e.sum - e.paid_sum, 0.0),
				rows: value.sort((a, b) => (a.date_at > b.date_at ? 1 : b.date_at > a.date_at ? -1 : 0)),
			};
		}
		return summaries;
	};

	const storeDate = date => {
		if (isDate(date)) {
			localStorage.setItem(
				'relevanceDate',
				JSON.stringify({
					value: date,
					expire: formatDate(new Date(), 'YYYY-MM-DD'),
				}),
			);
		}
	};

	$: if (relevanceDate) {
		if ($dct) {
			$transmitter = { ...$transmitter, dct: { ...$dct, date: relevanceDate } };
		}
		localStorage.setItem('relevanceDate', JSON.stringify({ value: relevanceDate, expire: moment().endOf('day') }));
	}

	onMount(async () => {
		let savedRelevanceDate = localStorage.getItem('relevanceDate');

		if (savedRelevanceDate) {
			savedRelevanceDate = JSON.parse(savedRelevanceDate);
			relevanceDate = moment().isAfter(savedRelevanceDate.expire) ? moment().format('YYYY-MM-DD') : savedRelevanceDate.value;
		} else {
			relevanceDate = moment().format('YYYY-MM-DD');
		}

		if ($procedure && $procedure.id) {
			fetchGet('/api/private/running_costs', { procedure_id: $procedure.id })
				.then(result => {
					$transmitter.running_costs = result.rows;
					$transmitter.payouts = result.payouts;
					$transmitter.dct = result.running_costs_reestr;
					repayment_orders = result.repayment_orders;
				})
				.then(() => (mounted = true));

			stages =
				$procedure.bankrupt_type == 'Organization'
					? ['first', 'second', 'third', 'fourth', 'fifth']
					: $procedure.bankrupt_type == 'Person'
						? ['first', 'second', 'third', 'fourth']
						: [];
		}
	});

	$: downloadFileName = `Реестр текущих платежей (${$currentData.bankruptShortName} в ${$procedure?.phase_short_name}) от ${formatDate(relevanceDate, 'DD.MM.YYYY')}.pdf`;

	$: runningCosts = toArray($transmitter.running_costs).filter(r => toDate(r.date_at) <= toDate(relevanceDate));
	$: buildSummary(toArray(runningCosts), toArray($transmitter.payouts));
	$: storeDate(relevanceDate);
</script>

<!-- TODO: жуткая кривизна построения страницы, разобраться с кнопками и таблицами -->
<Header><span slot="name">Расходы</span></Header>
<div class="dashboard flex-slot">
	<div class="m-b-lg mb-0 add-cost-btn">
		<button
			class="btn btn-sm btn-warning m-r-sm ml-1 ml-sm-0"
			disabled={!$procedureAvailable || !$procedure?.id}
			on:click={$procedureAvailable && handleNewRunningCostItem}
		>
			+ Расход
		</button>
	</div>
	{#if $transmitter.running_costs && runningCosts.length < $transmitter.running_costs.length}
		<div class="hint text-danger">Есть данные с более поздней датой</div>
	{/if}
	<ul class="nav navbar-top-links tools-bar justify-content-between">
		<li class="m-l-sm reestr-date">
			<Datepicker {...runningCostsReestrFields} disabled={!$procedureAvailable || !$procedure?.id} bind:value={relevanceDate} />
		</li>
		<li class="ml-4">
			<DownloadIcon
				dctId={$dct && $dct.id}
				disabled={!$procedureAvailable || !$procedure?.id || isBlank($dct)}
				pdfData={() => getTemplate($transmitter).preview}
				{downloadFileName}
			/>
		</li>
		<li class="ml-3 mr-2">
			<PrintIcon
				dctId={$dct && $dct.id}
				disabled={!$procedureAvailable || !$procedure?.id || isBlank($dct)}
				pdfData={() => getTemplate($transmitter).preview}
			/>
		</li>
	</ul>
</div>
<div class="main-table mt-2 footer-margin">
	<table class="table table-hover table-mobile border-collapse_separate">
		<thead class="thead-light">
			<tr>
				<th class="text-center p-w-xs">Дата расхода</th>
				<th class="text-center p-w-xs">Текущий кредитор</th>
				<th class="text-center p-w-xs">Вид расхода</th>
				<th class="text-center p-w-xs">Расходы, руб.</th>
				<th class="text-center p-w-xs">Платежи, руб.</th>
				<th class="text-center p-w-xs">Долг, руб.</th>
			</tr>
		</thead>
		<tbody class="text-center">
			{#each stages as key}
				<tr>
					<td class="text-left font-weight-bold">{repayment_orders[key]}</td>
					<td></td>
					<td></td>
					{#if isPresent(summaries[key])}
						<td class="text-right font-weight-bold">{formatSum(summaries[key].rows.reduce((s, e) => s + e.sum, 0.0))}</td>
						<td class="text-right font-weight-bold">{formatSum(summaries[key].rows.reduce((s, e) => s + e.paid_sum, 0.0))}</td>
						<td class="text-right font-weight-bold">{formatSum(summaries[key].rows.reduce((s, e) => s + e.sum - e.paid_sum, 0.0))}</td>
					{:else}
						<td class="text-right font-weight-bold">0.00</td>
						<td class="text-right font-weight-bold">0.00</td>
						<td class="text-right font-weight-bold">0.00</td>
					{/if}
				</tr>
				{#if mounted}
					{#if isPresent(summaries[key])}
						{#each summaries[key].rows as row}
							<tr class="cursor_pointer" on:click|stopPropagation={() => handleEditRunningCostItem(row.id)}>
								<td>{formatDate(row.date_at, 'DD.MM.YYYY')}</td>
								<td class="text-left">{row.counterparty_name}</td>
								<td class="text-left">{row.expense_type_text}</td>
								<td class="text-right">{formatSum(row.sum)}</td>
								<td class="text-right">{formatSum(row.paid_sum)}</td>
								<td class="text-right">{formatSum(row.sum - row.paid_sum)}</td>
							</tr>
						{/each}
					{:else}
						<tr><td class="text-center text-secondary" colspan="6">Нет данных</td></tr>
					{/if}
				{:else}
					<tr><td class="text-center text-secondary" colspan="6"><i class="fa fa-spinner fa-pulse fa-icon-large"></i></td></tr>
				{/if}
			{/each}
		</tbody>
		<tfoot>
			<tr>
				<th class="text-right">ИТОГО:</th>
				<th></th>
				<th></th>
				<th class="text-right">{formatSum(Object.values(summaries).reduce((sum, e) => sum + e.sum, 0.0))}</th>
				<th class="text-right">{formatSum(Object.values(summaries).reduce((sum, e) => sum + e.paid_sum, 0.0))}</th>
				<th class="text-right">{formatSum(Object.values(summaries).reduce((sum, e) => sum + e.sum - e.paid_sum, 0.0))}</th>
			</tr>
		</tfoot>
	</table>
</div>

<RunningCostModal bind:this={runningCostModal} />

<style lang="scss">
	.dashboard {
		width: 100%;
		margin-bottom: 0;

		/*.flex-grow-slot {
	    justify-content: flex-end;
		}*/
		.reestr-date {
			position: relative;
			margin-bottom: -1rem;

			.hint {
				position: absolute;
				bottom: -0.4rem;
				right: -0.7rem;
			}
		}
	}
	.main-table {
		font-size: inherit;
		background-color: #ffffff;
		margin-bottom: 40px;
		table {
			th {
				font-size: inherit;
			}
		}
	}
	@media (max-width: 576px) {
		.reestr-date {
			width: 54%;
		}
		.add-cost-btn {
			display: flex;
			align-items: flex-end;
		}
		.navbar-top-links li {
			align-self: end;
		}
		.hint {
			position: absolute;
			top: 85px;
			right: 0;
		}
		/*.datetimepicker{
			width: 150px;
		}*/
	}

	:global(#datepicker-running_costs-date.datetimepicker) {
		width: 165px !important;
	}
</style>
